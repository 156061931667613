<template>
  <div>
        <a-row class="content">
            <a-col :md="24" :lg="16" :xl="16" class="contentL" v-if="!orderNo">
                <div class="title">评价证书申请</div>
                <div class="contentLT"><img src="../../assets/images/evaluateImg01.png" alt=""><p>申请评价证书前须先进行版权登记，确认版权权属后方可申请评价证书！</p><router-link to="/Register">前往版权登记</router-link></div>
                <div class="contentLB"><p>评价服务引导</p>
                <ul>
                    <li><i>1</i><p>版权登记</p><span>填写作品信息进行版权登记。</span></li>
                    <li><i>2</i><p>获取版权证书</p><span>登记完成获取版权登记证书。</span></li>
                    <li><i>3</i><p>提交评价申请</p><span>确认评价作品信息，提交评价申请。</span></li>
                    <li><i>4</i><p>颁发评价证书</p><span>评价完成，颁发作品评价证书。</span></li>
                </ul>
                </div>
            </a-col>
            <a-col :md="24" :lg="16" :xl="16" class="contentL" v-if="orderNo">
                <div class="title">评价作品信息确认</div>
                <div class="contentLT infoView">
                    <div class="infoBox"><div><p>登记号</p><span class="registerNo" v-if="orderInfo.certificateInfo">{{orderInfo.certificateInfo.body.data.registerCode}}</span></div></div>
                    <div class="infoBox">
                        <div><p>作品名称</p><span>{{orderInfo.subject}}</span></div>
                        <div><p>作者</p><span v-for="(item,index) in orderInfo.authors" :key="index">{{item.name}}</span></div>
                    </div>
                    <div class="infoBox">
                        <div><p>著作权人</p><span v-if="orderInfo.certificateInfo">{{orderInfo.certificateInfo.body.data.obligee}}</span><span v-else>{{obligeeDetail.userName}}</span></div>
                        <div><p>发表状态</p><span v-if="orderInfo.publishStatus">{{ constants.publishStatus[orderInfo.publishStatus * 1-1].label }}</span></div>
                    </div>
                    <div class="infoBox">
                        <div><p>创作性质</p><span v-if="orderInfo.creationProperty">{{ constants.creationNature[orderInfo.creationProperty * 1-1].label }}</span></div>
                        <div><p>作品类型</p><span v-if="orderInfo.worksType">{{ constants.worksType[orderInfo.worksType * 1-1].label }}</span></div>
                    </div>
                    <div class="infoBox">
                        <div><p>创作完成日期</p><span v-if="orderInfo.completeTime">{{ orderInfo.finishTimeText }}</span></div>
                        <div><p>首次发表日期</p><span v-if="orderInfo.publishTime">{{ orderInfo.publishTimeText }}</span></div>
                    </div>
                    <div class="infoBox">
                        <div><p>作品文件</p>
                            <!-- 如果是图片格式，点击可放大 -->
                            <span class="file" v-if="orderInfo.items && orderInfo.items[0].fileType == 'png' || orderInfo.items[0].fileType == 'jpg'"  @click="previewImg(orderInfo.items[0].downloadUrl)"><i class="icon iconfont">&#xe67b;</i> {{orderInfo.items[0].sourceName}}.{{orderInfo.items[0].fileType}}</span>
                            <span class="file" v-else><i class="icon iconfont">&#xe67b;</i> {{orderInfo.items[0].sourceName}}.{{orderInfo.items[0].fileType}}</span>
                        </div>
                    </div>
                </div>

                <div class="title">信息补充</div>
                <div class="infoForm">
                    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                        <el-form-item label="发表平台" prop="publicationPlatform" >
                            <el-input v-model="form.publicationPlatform" placeholder="请输入发表平台"></el-input>
                        </el-form-item>
                        <el-form-item class="labelNone" prop="orderAmount">
                            <div class="amount">价格：<p>¥ {{ form.orderAmount }} </p></div>
                        </el-form-item>
                        <el-form-item class="labelNone"  prop="agreement" style="margin: 15px 0;">
                            <div class="checkBox"><el-checkbox v-model="agreement"></el-checkbox> 我已认证阅读并同意《用户协议》</div>
                        </el-form-item>
                        <el-form-item class="labelNone">
                            <el-button type="primary" @click="onSubmit" >提交申请</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </a-col>
            <a-col :md="0" :lg="1" :xl="1"></a-col>
            <a-col :md="24" :lg="7" :xl="7" class="contentR">
                <div class="contentRT">
                    <div class="title">评价服务</div>
                    <ul>
                        <li><router-link to="/Evaluate/About?index=1"><img src="../../assets/images/evaluateIcon01.png" alt=""><p>评价机构</p></router-link></li>
                        <li><router-link to="/Evaluate/About?index=2"><img src="../../assets/images/evaluateIcon02.png" alt=""><p>评价范围</p></router-link></li>
                        <li><router-link to="/Evaluate/About?index=3"><img src="../../assets/images/evaluateIcon03.png" alt=""><p>评价标准</p></router-link></li>
                        <li><router-link to="/Evaluate/About?index=4"><img src="../../assets/images/evaluateIcon04.png" alt=""><p>评价流程</p></router-link></li>
                    </ul>
                </div>
                <div class="contentRB">
                    <div class="title">评价指南</div>
                    <p><i>1.</i><span>评价等级受国家认可，全国通用；</span></p>
                    <p><i>2.</i><span>评价作品须是本人原创作品，避免版权纠纷；</span></p>
                    <p><i>3.</i><span>作品评价证书须获取版权登记号，所以作品需要先进行版权登记才可申请评价证书。</span></p>
                    <p><i>4.</i><span>可登录国家版权局官网(www.ncac.gov.cn)查询作品证书登记版权信息。</span></p>
                    <p><i>5.</i><span>可登录中文出版物知识产权评价中心官网(www.cpipip.cn)查询作品评价证书信息。</span></p>
                    <div class="previewImg" @click="previewImg('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/evaluateCertificate.png')">查看评价证书样本</div>
                </div>
            </a-col>
        </a-row>

        <!-- 图片预览 -->
        <el-image-viewer
            v-if="showImageViewer"
            :url-list="[showImageUrl]"
            hide-on-click-modal
            teleported
            :on-close="closePre"
        />

        <!-- 支付弹窗 -->
        <pay-dialog ref="payDialog" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import constants from "@/utils/constants";
import { queryRegisterOrder,queryPaymentOrderByOutBizNp,applyEvaluationOrder } from "@/api/EvidenceService";
import { queryObligeeDetail } from "@/api/ObligeeService";
import PayDialog from "../../components/PayDialog";
export default {
  components: {
      'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),
      PayDialog,
  },
  data () {
    return {
        constants,
        showImageViewer: false,
        showImageUrl: '',

        orderNo: this.$route.query.orderNo,
        orderInfo: {certificateInfo:{body:{data:{}}},items:[{}]},
        obligeeDetail: {},
        form: {
            publicationPlatform: '',
            orderAmount: '0.00'
        },
        agreement: false,

        rules: {
            publicationPlatform: [{ required: true, message: "请输入发表平台", trigger: "blur" },],
            agreement: [{ required: true, validator: this.valiAgreement, trigger: "change" },],
        },
    }
  },
  computed: {
    ...mapGetters(['mid','userInfo','evidencePaymentPrice','vipInfo'])
  },
  created() {
      if(this.orderNo){
          this.loadData();
      }
  },
  methods: {
      previewImg(url){
        document.body.style.overflow = 'hidden';
        this.showImageUrl = url;
        this.showImageViewer = true;
      },
      closePre(){
        document.body.style.overflow = 'auto';
        this.showImageUrl = '';
        this.showImageViewer = false;
      },

      //获取订单相关信息
      loadData() {
        queryRegisterOrder(this.orderNo).then(({ data }) => {
            this.orderInfo = data;
            this.orderInfo.paymodeText = constants.paymode[this.orderInfo.paymode];
            this.orderInfo.finishTimeText = this.dayjs(this.orderInfo.completeTime * 1000).format("YYYY-MM-DD");
            this.orderInfo.createTimeText = this.dayjs(this.orderInfo.createTime * 1000).format("YYYY-MM-DD");
            this.orderInfo.updateTimeText = this.dayjs(this.orderInfo.updateTime * 1000).format("YYYY-MM-DD");
            if(this.orderInfo.publishTime){
                this.orderInfo.publishTimeText = this.dayjs(this.orderInfo.publishTime * 1000).format("YYYY-MM-DD");
            }
            if (this.orderInfo.status != "10") {
            queryPaymentOrderByOutBizNp(this.orderNo, "2").then(({ data }) => {
                this.paymentInfo = data;
                this.paymentInfo.payTimeText = this.dayjs(this.paymentInfo.updateTime * 1000).format("YYYY-MM-DD");
            });
            }
            if(this.orderInfo.status!='21'){
                setTimeout(this.loadData,30*1000);
            }

            // 获取相关 权利人/作者，以及权利人编码
            queryObligeeDetail(data.obligee[0]).then(({ data }) => {
                // console.log('获取权利人相关信息',data);
                this.obligeeDetail = data;
            });

            // 计算价格
            // calAmount(){
                let amount=0;
                let existConfig=this.evidencePaymentPrice.exist;
                if(this.orderInfo.items.length > 0){
                    for (let i = 0; i < this.orderInfo.items.length; i++) {
                    const file = this.orderInfo.items[i];
                    //
                    amount+=existConfig.basePrice*1+(Math.ceil(file.used*1/(1024*1024))* existConfig.sizePrice*1);

                    }
                    }else{
                    amount = '0.00'
                    }

                this.form.orderAmount=(amount).toFixed(2);
            // },

        });
      },

      // 自定义验证同意协议
      valiAgreement(rule, value, callback){
        if (!this.agreement) {
            callback(new Error("请勾选同意协议"));
            return;
        }
        callback();
      },

      // 提交评价表单
    async onSubmit(){
        try {
          const formBaseResult = await this.$refs["form"].validate();
          //可添加其他验证
        } catch (error) {
          // 有错误,定位到错误
          // eslint-disable-next-line
          console.error(error);
          // this.mixinsScrollView();
          return;
        }
        if (this.Info) { // 如果已经提交过订单了，就阻止重复提交
          this.$refs.payDialog.showDialog(this.Info);
          this.isPayDialogVisible = true;
          return false;
        }
        let authors = [];
        for(let i in this.orderInfo.authors){
            authors.push(this.orderInfo.authors[i].name)
        }
        let params = {
            "workName": this.orderInfo.subject,  // 作品名称
            "workCategory": this.orderInfo.worksType,                                    // 作品类型
            "creationNature": this.orderInfo.creationProperty,                                 // 创作性质
            "creationCompletionDate": this.orderInfo.finishTimeText,            // 完成日期
            "publicationStatus": this.orderInfo.publishStatus,                             //发表状态
            "publicationPlatform": this.form.publicationPlatform,                    //发表平台
            "publicationDate": this.orderInfo.publishTimeText,                     // 发表日期
            "author": authors,                                   // 作者
            "copyrightOwnerCodes": this.orderInfo.obligee,   // 著作权人
            "registerCode": this.orderInfo.certificateInfo.body.data.registerCode,        // 作品登记号
            "currency": this.orderInfo.currency,                                    
            "syncBlockChain": 'ethc',
            "paymode": this.orderInfo.paymode,
            "items": this.orderInfo.items,
        }
        // 验证成功，提交表单
       applyEvaluationOrder(params).then(({ data }) => {
            // that.order = data;
            this.Info = data;
            // if (data.paymentStatus == "21") {   // 21是已经支付成功
            if (data.orderStatus == "12") {   // 12是已经支付成功，正在检测中
                this.bus.$emit("refreshVip");
                this.onSetPayDialogClose(true);
            } else {
                this.$refs.payDialog.showDialog(data);
                this.isPayDialogVisible = true;
            }
        });
    },
    onSetPayDialogClose(result) {
      console.log('支付成功result:',result);
      // 如果支付成功，跳转到详情页面
      if (result) {
        this.$router.push({
          path: "/Mine/MyEvaluate",
          // query: { orderNo: this.Info.orderNo },
        });
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.content{margin-bottom: 50px;margin-top: 20px;}
.contentL,.contentRT,.contentRB{background: #FFFFFF;box-shadow: 0px 0px 10px 0px rgba(2,37,116,0.08);border-radius: 8px;}

.contentL{padding: 20px 40px 50px 40px;}
.contentL .title{font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;font-size: 22px;color: rgba(0,18,22,0.85);line-height: 32px;padding-left: 20px;position: relative;margin-bottom: 20px;}
.contentL .title::before{content: '';width: 4px;height: 22px;background: #007DFF;border-radius: 4px;position: absolute;left: 0;top: 5px;}
.contentL .contentLT{box-shadow: 0px 0px 7px 0px rgba(0,70,144,0.09);padding: 20px 0 50px 0;}
.contentL .contentLT img{width: 360px;height: 270px;display: block;margin: 0 auto;max-width: 100%;}
.contentL .contentLT p{font-family: 'AlibabaPuHuiTiR';font-size: 18px;color: #666666;line-height: 25px;text-align: center;width: 440px;margin: 10px auto 30px auto;max-width: 100%;padding: 0 20px;}
.contentL .contentLT a{display: block;width: 162px;height: 40px;background: #316EF4;border-radius: 4px;margin: 0 auto;text-align: center;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;font-size: 14px;color: #FFFFFF;
    line-height: 40px;}
.contentL .contentLB{width: 100%;min-height: 170px;background: rgba(0,0,0,0.02);border-radius: 6px;margin-top: 20px;padding: 20px 30px;}
.contentL .contentLB p{font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 400;font-size: 20px;color: rgba(51,51,51,0.85);line-height: 20px;}
.contentL .contentLB ul{margin-top: 20px;display: flex;align-items: flex-start;justify-content: space-between;}
.contentL .contentLB ul li{width: 25%;padding-left: 36px;position: relative;}
.contentL .contentLB ul li:nth-child(1)::after{content: '';width: 48px;height: 1px;background: rgba(0,0,0,0.15);position: absolute;right: 5px;top: 15px;}
.contentL .contentLB ul li:nth-child(2)::after{content: '';width: 18px;height: 1px;background: rgba(0,0,0,0.15);position: absolute;right: 5px;top: 15px;}
.contentL .contentLB ul li:nth-child(3)::after{content: '';width: 18px;height: 1px;background: rgba(0,0,0,0.15);position: absolute;right: 5px;top: 15px;}
.contentL .contentLB ul li i{font-style: normal;display: block;width: 30px;height: 30px;line-height: 28px;text-align: center;border: 1px solid rgba(0,0,0,0.15);border-radius: 50%;color: rgba(0,0,0,0.15);position: absolute;left: 0;top: 0;}
.contentL .contentLB ul li p{height: 32px;line-height: 32px;font-family: 'PingFangSC, PingFang SC';font-size: 15px;color: rgba(0,0,0,0.45);white-space: nowrap;}
.contentL .contentLB ul li span{font-family: 'PingFangSC, PingFang SC';font-size: 13px;color: rgba(0,0,0,0.25);line-height: 22px;}

.infoView{padding: 20px 40px!important;margin-bottom: 20px;}
.infoView .infoBox{width: 100%;display: flex;align-items: center;padding: 10px 0;}
.infoView .infoBox div{width: 60%;display: flex;align-items: center;}
.infoView .infoBox div:nth-child(2){width: 40%;}
.infoView .infoBox:last-child div{width: 100%;}
.infoView .infoBox div p{font-family: 'PingFangSC, PingFang SC';font-weight: 600;font-size: 14px;color: rgba(0,18,22,0.45);line-height: 24px;width: 100px;padding: 0;margin: 0;text-align: left;}
.infoView .infoBox div span{font-family: 'PingFangSC, PingFang SC';font-weight: 400;font-size: 14px;color: #001216;line-height: 24px;white-space: nowrap;}
.infoView .infoBox div span.registerNo{display: block;height: 30px;line-height: 30px;padding: 0 10px;background: #EAF3FF;color: #104189;border-radius: 4px;}
.infoView .infoBox div span.file{color: #316EF4;cursor: pointer;max-width: calc(100% - 100px);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.infoForm{padding: 0 40px;}
/deep/.el-form-item__label{text-align: left;font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 500;font-size: 14px;color: #333333;line-height: 40px;}
/deep/.el-input{width: 420px;max-width: 100%;}
.amount{font-family: 'PingFangSC, PingFang SC';font-weight: 500;font-size: 14px;color: #000000;line-height: 22px;display: flex;align-items: center;}
.amount p{display: inline-block;color: #316EF4;font-size: 22px;font-weight: 500;}
.checkBox{font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: 400;font-size: 12px;color: #415170;line-height: 18px;}
/deep/.el-button{display: block;margin-top: 20px;width: 130px;}
.el-form-item:nth-child(2){margin-top: 100px;}
/deep/.labelNone .el-form-item__content{margin-left: 0!important;}

.contentRT,.contentRB{border-top: 5px solid #77A4FA;padding: 20px 30px;margin-bottom: 20px;}
.contentRT .title,.contentRB .title{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 22px;color: #000000;line-height: 36px;margin-bottom: 10px;}
.contentRT ul{display: flex;flex-wrap: wrap;justify-content: space-between;}
.contentRT ul li{display: block;width: 48%;height: 130px;background: #FFFFFF;box-shadow: 0px 2px 10px 0px rgba(48,83,147,0.05);border-radius: 8px;border: 1px solid #E9EBEE;
    text-align: center;margin-bottom: 10px;}
.contentRT ul li a{display: block;width: 100%;height: 100%;padding: 10px 0;}
.contentRT ul li img{width: 80px;height: 80px;}
.contentRT ul li p{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 18px;color: #333333;line-height: 30px;}
.contentRB p{margin-bottom: 10px;}
.contentRB p i{font-family: 'DIN, DIN';font-size: 14px;color: #316EF4;margin-right: 4px;}
.contentRB p span{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #000000;line-height: 21px;}
.contentRB .previewImg{display: inline-block;margin-top: 10px;height: 20px;border-bottom: 1px solid #316EF4;font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #316EF4;
    line-height: 18px;cursor: pointer;}

// 控制放大证书的大小，全屏看起来太大
::v-deep .el-image-viewer__img{max-width: 70% !important;height: auto !important;}

@media screen and (max-width: 991px){
.contentL .contentLB ul{display: block;}
.contentL .contentLB ul li{width: 100%;}
.contentL .contentLB ul li::after{display: none;}
}
</style>
